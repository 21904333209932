import React, { Component } from 'react'
import $ from 'jquery';
import Martian from "../../assets/images/slideImg2.jpg"



class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><img src={Martian} alt="" /></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">The Martian</h2>

                                <h2 className="artist_name"><span> By: </span> Fabio Giampetrio </h2>
                                <div className="desc">
                                    <p>A portrait. In the Metromorphosis series the boundaries between portraits and urban landscapes collapse into a play of lights and shadows. Oil on canvas, Physical canvas size 47\" x 51\"</p>
                                </div>

                                <a href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/18946" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;