import React,{Component} from "react";

class Loader extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <>
                <div id="page-loader" className="qodef-m qodef-layout--rotating-cubes">
                    <div className="page-loader-inner">
                        <div className="loader-spinner">
                            <div className="loader-cubes">
                                <div className="loader-cube loader-cube--1"></div>
                                <div className="loader-cube loader-cube--2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Loader;