import React, { Component } from 'react'
import $ from 'jquery';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Home from './pages/Home'
import About from './pages/About'
import Collection from './pages/Collection'
import Investments from './pages/Investments'
// import Metaverse from './pages/Metaverse'
import Contact from './pages/Contact'
import PortfolioGoat from './pages/PortfolioItem-goat'
import PortfolioEad from './pages/PortfolioItem-eardley'
import PortfolioRain from './pages/PortfolioItem-rain-village'
import PortfolioElec from './pages/PortfolioItem-an-electric-storm'
import PortfolioUni from './pages/PortfolioItem-universe-edge'
import PortfolioSkat from './pages/PortfolioItem-skateboard-rides-you'

import PortfolioWave from './pages/PortfolioItem-wavestep'
import PortfolioMart from './pages/PortfolioItem-martian'
import PortfolioDragon from './pages/PortfolioItem-dragon-boi'


import Portfolioseasons from './pages/PortfolioItem-seasons'
import PortfoliotheRaven from './pages/PortfolioItem-the-raven'
import Portfoliovisualmeditation from './pages/PortfolioItem-visual-meditation'
import Portfoliothemartian from './pages/PortfolioItem-the-martian'
import PortfoliospaceStation from './pages/PortfolioItem-spaceStation'
import Portfoliohyperreality from './pages/PortfolioItem-hyperreality'
import Portfoliouniversemachine from './pages/PortfolioItem-the-universe-machine'
import Portfoliodaughter from './pages/PortfolioItem-daughter'
import Portfoliorabbithole from './pages/PortfolioItem-down-the-rabbit-hole'
import Portfoliowavestep from './pages/PortfolioItem-wavestep'
import Portfolioonlybeone from './pages/PortfolioItem-there-can-only-be-one'
import Portfoliothedrop from './pages/PortfolioItem-the-drop'
import Portfolioexcalibur from './pages/PortfolioItem-excalibur'
import Portfoliopsychotropiccolony from './pages/PortfolioItem-the-psychotropic-colony'
import Portfoliodragonboi from './pages/PortfolioItem-dragon-boi'
import Portfolioluminosos from './pages/PortfolioItem-prifma-luminosos'
import PortfoliolastShawarma from './pages/PortfolioItem-the-last-shawarma'


/* import Portfolio from './pages/PortfolioItem-' */


import mainLogo from '../assets/images/main__logo.png';
import respo_logo from '../assets/images/main__logo.png';


class Header extends Component {

    render() {
        return (
            <>
                <Router>
                    <header>
                        <nav>
                            <Link to="/" className="logo" href="/">
                                <img src={`${mainLogo}`} alt="" className="desktop_logo" />
                                <img src={`${respo_logo}`} alt="" className="respo_logo" />
                            </Link>
                            <ul>
                                <li><Link to="/about">about</Link></li>
                                <li><Link to="/collection" >art collection</Link></li>
                                {/* <li><Link to="/metaverse" >meta gallery</Link></li> */}
                                <li><Link to="/investments" >investments</Link></li>
                                <li><Link to="/contact" >contact</Link></li>
                            </ul>
                            <div className="sidebar__toggle">
                                <span className="toggle__icon"></span>
                            </div>
                        </nav>
                    </header>


                    <Switch>
                        <Route path="/" exact>
                            <Home />
                        </Route>

                        <Route path="/about">
                            <About />
                        </Route>

                        <Route path="/collection">
                            <Collection />
                        </Route>

                        <Route path="/investments">
                            <Investments />
                        </Route>

                        {/* <Route path="/metaverse">
                            <Metaverse />
                        </Route> */}

                        <Route path="/contact">
                            <Contact />
                        </Route>

                        <Route path="/portfolio-item/goat">
                            <PortfolioGoat />
                        </Route>
                        <Route path="/portfolio-item/eardley">
                            <PortfolioEad />
                        </Route>
                        <Route path="/portfolio-item/rain-village">
                            <PortfolioRain />
                        </Route>
                        <Route path="/portfolio-item/an-electric-storm">
                            <PortfolioElec />
                        </Route>
                        <Route path="/portfolio-item/universe-edge">
                            <PortfolioUni />
                        </Route>
                        <Route path="/portfolio-item/skateboard-rides-you">
                            <PortfolioSkat />
                        </Route>
                        <Route path="/portfolio-item/wavestep">
                            <PortfolioWave />
                        </Route>
                        <Route path="/portfolio-item/martian">
                            <PortfolioMart />
                        </Route>

                        <Route path="/portfolio-item/dragon">
                            <PortfolioDragon />
                        </Route>


                        <Route path="/portfolio-item/seasons">
                            <Portfolioseasons />
                        </Route>

                        <Route path="/portfolio-item/the-Raven">
                            <PortfoliotheRaven />
                        </Route>

                        <Route path="/portfolio-item/visual-meditation">
                            <Portfoliovisualmeditation />
                        </Route>
                        <Route path="/portfolio-item/the-martian">
                            <Portfoliothemartian />
                        </Route>
                        <Route path="/portfolio-item/spaceStation">
                            <PortfoliospaceStation />
                        </Route>
                        <Route path="/portfolio-item/hyperreality">
                            <Portfoliohyperreality />
                        </Route>
                        <Route path="/portfolio-item/the-universe-machine">
                            <Portfoliouniversemachine />
                        </Route>
                        <Route path="/portfolio-item/daughter">
                            <Portfoliodaughter />
                        </Route>
                        <Route path="/portfolio-item/down-the-rabbit-hole">
                            <Portfoliorabbithole />
                        </Route>

                        <Route path="/portfolio-item/there-can-only-be-one">
                            <Portfolioonlybeone />
                        </Route>
                        <Route path="/portfolio-item/the-drop">
                            <Portfoliothedrop />
                        </Route>
                        <Route path="/portfolio-item/excalibur">
                            <Portfolioexcalibur />
                        </Route>
                        <Route path="/portfolio-item/the-psychotropic-colony">
                            <Portfoliopsychotropiccolony />
                        </Route>
                        <Route path="/portfolio-item/dragon-boi">
                            <Portfoliodragonboi />
                        </Route>

                        <Route path="/portfolio-item/the-last-shawarma">
                            <PortfoliolastShawarma />
                        </Route>

                        <Route path="/portfolio-item/prifma-luminosos">
                            <Portfolioluminosos />
                        </Route>






                    </Switch>


                </Router>
            </>
        )
    }
}

export default Header;