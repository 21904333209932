import React, { Component } from 'react'
import $ from 'jquery';
import TheUniverseMachine from "../../assets/images/the-Universe-Machine.mp4"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <video controls autoPlay loop muted src={TheUniverseMachine} alt=""></video>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">The Universe Machine</h2>

                                <h2 className="artist_name"><span> By: </span> oshua Davis </h2>
                                <div className="desc">
                                    <p>the Universe Machine # 001, is a generative program written using processing (JAVA). This 42 (the answer to life, the universe, and everything) second real-time generative animation uses a particle (200 particles) flocking behavior randomly mapped with a bank of visual assets and colors. This project is also an exercise in slowness... a death variable tracks each particles lifespan... and after each of the particles have lived their lives, a snapshot of the universe is captured.</p>
                                </div>

                                <a href="https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/2992" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;