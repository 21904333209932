import React, { Component } from 'react'
import $ from 'jquery';
import Home from "../../assets/images/homevideo.mp4"


class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><video controls autoPlay loop muted src={Home} alt=""></video></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">prifma.luminosos</h2>
                                <h2 className="artist_name"><span> By: </span> Gmunk </h2>
                                <div className="desc">
                                    <p>prifma.luminosos By GMUNK & Bedtimes, 2021. 4K Clips Available for Download to all Buyers</p>
                                </div>

                                <a href="https://opensea.io/assets/0x0c53a39e62b4a4c0a54c0b33b2045af808158833/26000040004" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;