import React, { Component } from "react";
import $ from 'jquery';
import Aligned from '../../assets/images/partners/aligned-logo.png'
import AtomicForm from '../../assets/images/partners/Atomic-Form.png'
import Chainlink from '../../assets/images/partners/Chainlink.jpg'
import FingerprintsDAO from '../../assets/images/partners/FingerPrints-DAO.png'
import Flamingo from '../../assets/images/partners/flamingo-logo.png'
import NeonDAO from '../../assets/images/partners/Neon-DAO.jpg'
import RedDao from '../../assets/images/partners/reddao.png'
import IZUMO from '../../assets/images/partners/IZUMO.jpg'
import Synthetix from '../../assets/images/partners/Synthetix.png'
import Tribute from '../../assets/images/partners/tribute.png'
import YearnFinance from '../../assets/images/partners/Yearn-Finance.png'
import Artisant from '../../assets/images/partners/artisant-logo.png'
import Cowswap from '../../assets/images/partners/cowswap-logo.png'
import Gnosis from '../../assets/images/partners/gnosis-logo.png'
import Cosmos from '../../assets/images/partners/cosmos-logo.png'
import NoiseDao from '../../assets/images/partners/noisedao-logo.png'
import Unikura from '../../assets/images/partners/unikura.jpg'
import TributeBrand from '../../assets/images/partners/tribute-brand-logo.png'
import Cybr from '../../assets/images/partners/cybr.png'
import Glimmer from '../../assets/images/partners/glimmerdao.png'
import Aww from '../../assets/images/partners/aww.png'
import Ethereum from '../../assets/images/partners/ethereum.jpg'
import ninedcc from '../../assets/images/partners/9dcc.jpg'
import BasedAF from '../../assets/images/partners/basedaf.jpg'
import Storyverse from '../../assets/images/partners/Storyverse.png'
import Gabbyworld from '../../assets/images/partners/Gabbyworld.png'
import Oyl from '../../assets/images/partners/Oyl.png'
import Fountain from '../../assets/images/partners/Fountain.png'
import NYXL from '../../assets/images/partners/NYXL.png'

class Investments extends Component {
    src_path = window.src_path;
    prt_src_path = `${window.src_path}partners/`;
    constructor() {
        super();
        this.state = {
            src_path: window.src_path,

            partners: [
                //////// 1-5
                {
                    description: 'Open source platform to write and distribute decentralized applications.',
                    img: Ethereum,
                    href: `https://twitter.com/ethereum`,
                },
                {
                    description: 'A chain agnostic hub for Web3 and metaverse contents Alignment Engine',
                    img: AtomicForm,
                    href: `https://twitter.com/atomicform`,
                },
                {
                    description: 'An incubator and infrastructure for DAOs',
                    img: Tribute,
                    href: `https://twitter.com/tributelabsxyz`,
                },
                {
                    description: 'Creating Virtual Humans to change the world',
                    img: Aww,
                    href: `https://twitter.com/aww_inc`,
                },
                {
                    description: 'CYBR is a magazine and collective that focuses on futurism and tech culture',
                    img: Cybr,
                    href: `https://cybrmagazine.com/`,
                },
                /////// 6-10
                {
                    description: 'If you know, you know. Metaverse content monster.',
                    img: BasedAF,
                    href: `https://twitter.com/we_are_BasedAF`,
                },
                {
                    description: "High performance computing company focusing on Zero-Knowledge Proofs by utilizing Field Programmable Gate Arrays (FPGAs) hardware implementation",
                    img: Aligned,
                    href: `https://www.aligned.co`,
                },
                {
                    description: 'An NFT-focused DAO that aims to explore emerging investment opportunities for ownable, blockchain-based digital media and culture',
                    img: Flamingo,
                    href: `https://twitter.com/FLAMINGODAO`,
                },
                {
                    description: 'A collective building out the metaverse',
                    img: NeonDAO,
                    href: `https://twitter.com/neon_dao`,
                },
                {
                    description: 'A digital fashion DAO house',
                    img: RedDao,
                    href: `https://twitter.com/RED___DAO`,
                },
                /////// 11-15
                {
                    description: 'NFT investment DAO focused on Asia',
                    img: Glimmer,
                    href: `https://twitter.com/glimmerdao`,
                },
                {
                    description: 'A DAO supporting music NFTs and projects',
                    img: NoiseDao,
                    href: `https://twitter.com/noisedao`,
                },
                {
                    description: 'An NFT DAO curating and collecting artworks that use smart contracts in exceptionally creative ways',
                    img: FingerprintsDAO,
                    href: `https://twitter.com/FingerprintsDAO`,
                },
                {
                    description: 'Fractionalized Physical backed NFTs from Japan and Singapore',
                    img: Unikura,
                    href: `https://twitter.com/Unikura_NFT`,
                },
                {
                    description: 'A ■■ i ■',
                    img: IZUMO,
                    href: `https://twitter.com/IZUMOofficial`,
                },
                /////// 16-20
                {
                    description: 'A decentralized oracle network that enables smart contracts on any blockchain to leverage extensive off-chain resources, such as tamper-proof price data, verifiable randomness, keeper functions, external APIs, and much more',
                    img: Chainlink,
                    href: `https://twitter.com/chainlink`,
                },
                {
                    description: 'A new financial primitive enabling the creation of synthetic assets, offering unique derivatives and exposure to real-world assets on the blockchain',
                    img: Synthetix,
                    href: `https://twitter.com/synthetix_io`,
                },
                {
                    description: 'A yield aggregator that actively seeks and strategizes income opportunities in DeFi ecosystem',
                    img: YearnFinance,
                    href: `https://twitter.com/iearnfinance`,
                },
                {
                    description: 'NFT marketplace for wearable collectables and digital fashion art',
                    img: Artisant,
                    href: `https://twitter.com/artisantnft`,
                },
                {
                    description: 'A crypto-native luxury house and lifestyle platform',
                    img: ninedcc,
                    href: `https://twitter.com/9dccxyz`,
                },
                /////// 21-25
                {
                    description: 'TRIBUTE BRAND is a high-end digital fashion brand leading the way in contactless and cyber fashion',
                    img: TributeBrand,
                    href: `https://twitter.com/tribute_brand`,
                },
                {
                    description: 'Providing MEV protected trades',
                    img: Cowswap,
                    href: `https://twitter.com/MEVprotection`,
                },
                {
                    description: 'Decentralized infrastructure for the Ethereum ecosystem',
                    img: Gnosis,
                    href: `https://twitter.com/gnosischain`,
                },
                {
                    description: 'The open, scalable, and interconnected economy of the future',
                    img: Cosmos,
                    href: `https://twitter.com/cosmos`,
                },
                {
                    description: 'Collect & Play NFTs in playable stories',
                    img: Storyverse,
                    href: `https://twitter.com/storyverse_xyz`,
                },
                /////// 26-30
                {
                    description: 'AIGC Autonomous World',
                    img: Gabbyworld,
                    href: `https://twitter.com/gabby_world_`,
                },
                {
                    description: 'The Bitcoin operating system',
                    img: Oyl,
                    href: `https://twitter.com/OylDynamics`,
                },
                {
                    description: 'The brokerage of cryptoart',
                    img: Fountain,
                    href: `https://twitter.com/Fountainxyz`,
                },
                {
                    description: 'The first global gaming entertainment brand born from New York',
                    img: NYXL,
                    href: `https://twitter.com/NYXL`,
                }
            ]
        }
    }

    loadJs() {
        /* setTimeout(function() { $("#page-loader").fadeOut(1500); }, 3000); */
    }
    componentDidMount() {
        this.loadJs()
    }

    render() {
        return (
            <>
                <section className="about__main">
                    <div className="partner__sec">

                        <div className="partner__sec_inner">

                            {
                                this.state.partners.map((value, index) => {
                                    return <div className="partner">
                                        {value.href == '' ?
                                            (<a>
                                                <img src={value.img} alt="" />
                                                <div className="partner_details">
                                                    <p className="partner_desc">{value.description}</p>
                                                </div>
                                            </a>) :
                                            (<a target="_blank" href={value.href}>
                                                <img src={value.img} alt="" />
                                                <div className="partner_details">
                                                    <p className="partner_desc">{value.description}</p>
                                                </div>
                                            </a>)
                                        }

                                    </div>
                                })
                            }

                        </div>


                    </div>
                </section>
            </>
        )
    }
}

export default Investments;