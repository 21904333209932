import React, { Component } from 'react'
import $ from 'jquery';
import Skateboard from "../../assets/images/Skateboard-Rides-You.mp4"


class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><video controls autoPlay loop muted src={Skateboard} alt=""></video></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Skateboard Rides You</h2>

                                <h2 className="artist_name"><span> By: </span> Deekaymotion </h2>
                                <div className="desc">
                                    <p>The title says it. Skateboard rides you.</p>
                                </div>

                                <a href="https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/1338" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;