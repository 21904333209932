import React, { Component } from 'react'
import $ from 'jquery';
import Daughter from "../../assets/images/Daughter.mp4"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <video controls autoPlay loop muted src={Daughter} alt=""></video>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Daughter</h2>

                                <h2 className="artist_name"><span> By: </span> dam Swaab </h2>
                                <div className="desc">
                                    <p>Concept and Visuals: Adam Swaab. Audio: Pelican Sound</p><p>This piece was inspired by my daughter, watching her grow and change so much in her few short years so far. Every time I turn around, it seems she is someone new, yet, still her. Often, we play at a park, at one specific tree, of which climbing eludes her. The tree has a wonderful bark pattern, where you can see layers of history showing through. I felt inspired by that tree, and how I might connect that to feelings about my daughter, through motion.</p><p>Textural elements here are also inspired by pieces of my daughter’s life, particularly her favorite white coat, with an iridescent sheen to it. This was my way to bring a little bit of her to this piece.</p><p>For audio, Pelican Sound was tasked with conveying my keyword of “time,” which they have admiringly achieved.</p><p>2160x12160 .mp4 24 FPS</p>
                                </div>

                                <a href="https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/16994" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;