import React, { Component } from 'react'
import $ from 'jquery';
import Universe from "../../assets/images/gallery5.jpg"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><img src={Universe} alt="" /></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Universe Edge</h2>

                                <h2 className="artist_name"><span> By: </span> Six n Five </h2>
                                <div className="desc">
                                    <p>Part of the act two: “The Settlements”</p><p> is a reflection from a far distance that allows us to imagine how life could look in another physical condition. It’s probably inaccurate under the eyes of the science community, but it expresses our freedom to dream and to imagine. NOTE**This auction is only open to those who own an NFT by Six N. Five</p>
                                </div>

                                <a href="https://opensea.io/assets/0x0e4613bbf418adbea2fb6f2a349b6f2efb1d2005/54600040001" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;