import React from 'react'
import { Helmet } from 'react-helmet'
import $ from 'jquery'

import InfectedImage from '../assets/images/massonayvid13.jpg'
import InfectedVideo from '../assets/images/massonayvid13.mp4'
import EtherImage from '../assets/images/massonayvid14.jpg'
import EtherVideo from '../assets/images/massonayvid14.mp4'
import BiologicalImage from '../assets/images/massonayvid3.jpg'
import BiologicalVideo from '../assets/images/massonayvid3.mp4'
import Bear23 from '../assets/images/masonryimg28.jpg'
import Bear24 from '../assets/images/masonryimg29.png'
import GivingMyHeartImage from '../assets/images/massonayvid9.jpg'
import GivingMyHeartVideo from '../assets/images/massonayvid9.mp4'
import BloomedImage from '../assets/images/massonayvid4.jpg'
import BloomedVideo from '../assets/images/massonayvid4.mp4'
import GodbugImage from '../assets/images/massonayvid10.jpg'
import GodbugVideo from '../assets/images/massonayvid10.mp4'
import DesignerClientImage from '../assets/images/massonayvid6.jpg'
import DesignerClientVideo from '../assets/images/massonayvid6.mp4'
import CharacterTypeImage from '../assets/images/massonayvid5.jpg'
import CharacterTypeVideo from '../assets/images/massonayvid5.mp4'
import LongWayDownImage from '../assets/images/massonayvid16.jpg'
import LongWayDownVideo from '../assets/images/massonayvid15.mp4'
import Cybercluck from '../assets/images/masonryimg13.jpg'
import Tile from '../assets/images/masonryimg23.jpeg'
import LifeIsAMess from '../assets/images/masonryimg15.jpeg'
import Nostalgia from '../assets/images/masonryimg16.jpg'
import WhiteFordBranco from '../assets/images/masonryimg26.jpg'
import RetroFittedMachinesImage from '../assets/images/massonayvid21.jpg'
import RetroFittedMachinesVideo from '../assets/images/massonayvid21.mp4'
import NeonChaos from '../assets/images/masonryimg31.jpg'
import RunningInPlace from '../assets/images/masonryimg17.jpeg'
import SymphonyImage from '../assets/images/massonayvid23.jpg'
import SymphonyVideo from '../assets/images/massonayvid23.mp4'
import MagicalItem17Image from '../assets/images/massonayvid25.jpg'
import MagicalItem17Video from '../assets/images/massonayvid25.mp4'
import MilesHighImage from '../assets/images/massonayvid18.jpg'
import MilesHighVideo from '../assets/images/massonayvid18.mp4'
import MambaImage from '../assets/images/massonayvid7.jpg'
import MambaVideo from '../assets/images/massonayvid7.mp4'
import NebularImage from '../assets/images/masonryimg5-thumb.gif'
import NebularVideo from '../assets/images/masonryimg5.gif'
import GentlePush from '../assets/images/masonryimg4.gif'
import Tangerine from '../assets/images/masonryimg21.jpg'
import GameCube from '../assets/images/masonryimg3.gif'
import SystemOverrideImage from '../assets/images/massonayvid24.jpg'
import SystemOverrideVideo from '../assets/images/massonayvid24.mp4'
import PolyfloatImage from '../assets/images/massonayvid20.jpg'
import PolyFloatVideo from '../assets/images/massonayvid20.mp4'
import AmbianceImage from '../assets/images/massonayvid2.jpg'
import AmbianceVideo from '../assets/images/massonayvid2.mp4'
import GhostImage from '../assets/images/massonayvid8.jpg'
import GhostVideo from '../assets/images/massonayvid8.mp4'
import KawaiiImage from '../assets/images/massonayvid15.jpg'
import KawaiiVideo from '../assets/images/massonayvid15.mp4'
import ForgetImage from '../assets/images/massonayvid12.jpg'
import ForgetVideo from '../assets/images/massonayvid12.mp4'
import Chained from '../assets/images/masonryimg32.jpg'
import Tokyo from '../assets/images/masonryimg24.jpg'
import SadSnoopy from '../assets/images/masonryimg18.jpeg'
import Secrets from '../assets/images/masonryimg19.jpg'
import WorldInsideImage from '../assets/images/massonayvid12.jpg'
import WorldInsideVideo from '../assets/images/massonayvid12.mp4'
import VisitingFriendsImage from '../assets/images/massonayvid29.jpg'
import VisitingFriendsVideo from '../assets/images/massonayvid29.mp4'
import AM3Image from '../assets/images/massonayvid1.jpg'
import AM3Video from '../assets/images/massonayvid1.mp4'
import MoonImage from '../assets/images/massonayvid26.jpg'
import MoonVideo from '../assets/images/massonayvid26.mp4'
import WhyCareImage from '../assets/images/massonayvid30.png'
import WhyCareVideo from '../assets/images/massonayvid30.mp4'
import GeorgeFloyd from '../assets/images/masonryimg14.jpg'
import BattleOfNymphs from '../assets/images/masonryimg9.jpg'
import SingularityImage from '../assets/images/massonayvid22.jpg'
import SingularityVideo from '../assets/images/massonayvid22.mp4'
import DemonicCatImage from '../assets/images/masonryimg1-thumb.gif'
import DemonicCatVideo from '../assets/images/masonryimg1.gif'
import NyanCatImage from '../assets/images/masonryimg2-thumb.gif'
import NyanCatVideo from '../assets/images/masonryimg2.gif'
import TouchImage from '../assets/images/massonayvid28.jpg'
import TouchVideo from '../assets/images/massonayvid28.mp4'
import Carribean from '../assets/images/masonryimg10.jpg'
import HongkongImage from '../assets/images/massonayvid11.jpg'
import HongkongVideo from '../assets/images/massonayvid11.mp4'
import TokyoMetropolisImage from '../assets/images/massonayvid27.jpg'
import TokyoMetropolisVideo from '../assets/images/massonayvid27.mp4'
import Tier1Drive from '../assets/images/masonryimg22.jpg'
import SkateboardingCulture from '../assets/images/masonryimg20.jpeg'
import ChangMi from '../assets/images/masonryimg11.jpeg'
import BastardCountryside35 from '../assets/images/masonryimg8.jpg'
import BastardCountryside34 from '../assets/images/masonryimg7.jpg'
import MemoriesCigaretesImage from '../assets/images/massonayvid17.jpg'
import MemoriesCigaretesVideo from '../assets/images/massonayvid17.mp4'
import PayphoneImage from '../assets/images/massonayvid19.jpg'
import PayphoneVideo from '../assets/images/massonayvid19.mp4'
import AmericanDiners1 from '../assets/images/masonryimg6.jpeg'
import AmericanDiners2 from '../assets/images/masonryimg12.jpeg'
import UrbanAnomalies from '../assets/images/masonryimg25.jpeg'
import FormFarmMaster from '../assets/images/masonryimg30.png'



const CollectionMasonry = () => {

  const masonryData = [
    {
      "title": "Infected",
      "description": "",
      "author": "Beeple",
      "poster": InfectedImage,
      "popupSrc": InfectedVideo,
      "href": "https://opensea.io/assets/0xd92e44ac213b9ebda0178e1523cc0ce177b7fa96/100020009",
      "type": "video"
    },
    {
      "title": "Into the Ether",
      "description": "",
      "author": "Beeple",
      "poster": EtherImage,
      "popupSrc": EtherVideo,
      "href": "https://opensea.io/assets/0xd92e44ac213b9ebda0178e1523cc0ce177b7fa96/100030151",
      "type": "video"
    },
    {
      "title": "Biological Collectible",
      "description": "",
      "author": "Beeple",
      "poster": BiologicalImage,
      "popupSrc": BiologicalVideo,
      "href": "https://opensea.io/assets/0xdd012153e008346591153fff28b0dd6724f0c256/100010087",
      "type": "video"
    },
    {
      "title": "Bear-23",
      "description": "",
      "author": "Xcopy",
      "poster": Bear23,
      "popupSrc": Bear23,
      "href": "https://opensea.io/assets/0x909899c5dbb5002610dd8543b6f638be56e3b17e/57896044618658097711785492504343953929357251268187789727435788863410710528454",
      "type": "img"
    },
    {
      "title": "Bear-24",
      "description": "",
      "author": "Xcopy",
      "poster": Bear24,
      "popupSrc": Bear24,
      "href": "https://opensea.io/assets/0x909899c5dbb5002610dd8543b6f638be56e3b17e/57896044618658097711785492504343953929357251268187789727435788863410710527073",
      "type": "img"
    },
    {
      "title": "Giving my heart away",
      "description": "",
      "author": "Fewocious",
      "poster": GivingMyHeartImage,
      "popupSrc": GivingMyHeartVideo,
      "href": "https://opensea.io/assets/0xe4605d46fd0b3f8329d936a8b258d69276cba264/122",
      "type": "video"
    },
    {
      "title": "Bloomed",
      "description": "",
      "author": "Fvckrender",
      "poster": BloomedImage,
      "popupSrc": BloomedVideo,
      "href": "https://opensea.io/assets/0x1766ed38c58324744ea74c6161b8259e9aa995a9/13300020003",
      "type": "video"
    },
    {
      "title": "GODBUG",
      "description": "",
      "author": "PROBZZZ",
      "poster": GodbugImage,
      "popupSrc": GodbugVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/69353",
      "type": "video"
    },
    {
      "title": "Designer & Client",
      "description": "",
      "author": "Deekaymotion",
      "poster": DesignerClientImage,
      "popupSrc": DesignerClientVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/59391",
      "type": "video"
    },
    {
      "title": "Character Type",
      "description": "",
      "author": "Deekaymotion",
      "poster": CharacterTypeImage,
      "popupSrc": CharacterTypeVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/2201",
      "type": "video"
    },
    {
      "title": "Long Way Down",
      "description": "",
      "author": "Phil Tippett",
      "poster": LongWayDownImage,
      "popupSrc": LongWayDownVideo,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/27265",
      "type": "video"
    },
    {
      "title": "Cybercluck 2077",
      "description": "",
      "author": "BakaArts",
      "poster": Cybercluck,
      "popupSrc": Cybercluck,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/471",
      "type": "img"
    },
    {
      "title": "Tile [35, 12] - One Step at a Time",
      "description": "",
      "author": "Pak",
      "poster": Tile,
      "popupSrc": Tile,
      "href": "https://opensea.io/assets/0x7ccdc136619cddf744122a938b4448eda1590fe1/139",
      "type": "img"
    },
    {
      "title": "Life's a Mess",
      "description": "",
      "author": "Chewy Stoll",
      "poster": LifeIsAMess,
      "popupSrc": LifeIsAMess,
      "href": "https://opensea.io/assets/0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756/38842",
      "type": "img"
    },
    {
      "title": "Nostalgia",
      "description": "",
      "author": "Musketon",
      "poster": Nostalgia,
      "popupSrc": Nostalgia,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/1639",
      "type": "img"
    },
    {
      "title": "White Ford Bronco",
      "description": "",
      "author": "Brendan Dawes",
      "poster": WhiteFordBranco,
      "popupSrc": WhiteFordBranco,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/68027",
      "type": "img"
    },
    {
      "title": "Retrofitted Machines",
      "description": "",
      "author": "TOKYOLUV",
      "poster": RetroFittedMachinesImage,
      "popupSrc": RetroFittedMachinesVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/37999",
      "type": "video"
    },
    {
      "title": "Neon Chaos",
      "description": "",
      "author": "Anocam",
      "poster": NeonChaos,
      "popupSrc": NeonChaos,
      "href": "https://opensea.io/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/364851",
      "type": "img"
    },
    {
      "title": "Running In Place",
      "description": "",
      "author": "Sinclair",
      "poster": RunningInPlace,
      "popupSrc": RunningInPlace,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/16559",
      "type": "img"
    },
    {
      "title": "Symphony",
      "description": "",
      "author": "Roger Kilimanjaro",
      "poster": SymphonyImage,
      "popupSrc": SymphonyVideo,
      "href": "https://opensea.io/assets/0x95c43f41965b6d2ab803a9850dcc4ce3b9a064f7/23100010001",
      "type": "video"
    },
    {
      "title": "The Magical Item #17: Cheche: The Night Arcade",
      "description": "",
      "author": "Migrating Lines",
      "poster": MagicalItem17Image,
      "popupSrc": MagicalItem17Video,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/88218",
      "type": "video"
    },
    {
      "title": "Miles High",
      "description": "",
      "author": "BossLogic",
      "poster": MilesHighImage,
      "popupSrc": MilesHighVideo,
      "href": "https://opensea.io/assets/0x8ae06a29a816580cbd069e460dd57c6c964bb3da/7500020008",
      "type": "video"
    },
    {
      "title": "Forever Mamba",
      "description": "",
      "author": "BossLogic",
      "poster": MambaImage,
      "popupSrc": MambaVideo,
      "href": "https://opensea.io/assets/0x8ae06a29a816580cbd069e460dd57c6c964bb3da/7500010178",
      "type": "video"
    },
    {
      "title": "Nebular 5",
      "description": "",
      "author": "Jsf",
      "poster": NebularImage,
      "popupSrc": NebularVideo,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/22825",
      "type": "img"
    },
    {
      "title": "Gentle Push",
      "description": "",
      "author": "Colin Benders",
      "poster": GentlePush,
      "popupSrc": GentlePush,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/26595",
      "type": "img"
    },
    {
      "title": "Tangerine Dreams",
      "description": "",
      "author": "mvhvma",
      "poster": Tangerine,
      "popupSrc": Tangerine,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/22264",
      "type": "img"
    },
    {
      "title": "Game Cube VIII - Emulator",
      "description": "",
      "author": "Jerry Liu",
      "poster": GameCube,
      "popupSrc": GameCube,
      "href": "https://opensea.io/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/278476",
      "type": "img"
    },
    {
      "title": "System Override",
      "description": "",
      "author": "dannh",
      "poster": SystemOverrideImage,
      "popupSrc": SystemOverrideVideo,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/27686",
      "type": "video"
    },
    {
      "title": "Poly Float Pineapple",
      "description": "",
      "author": "VansDesign",
      "poster": PolyfloatImage,
      "popupSrc": PolyFloatVideo,
      "href": "https://opensea.io/assets/0xe4605d46fd0b3f8329d936a8b258d69276cba264/44",
      "type": "video"
    },
    {
      "title": "Ambiance",
      "description": "",
      "author": "Mark Malta",
      "poster": AmbianceImage,
      "popupSrc": AmbianceVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/7389",
      "type": "video"
    },
    {
      "title": "Ghost",
      "description": "",
      "author": "Marc Tudisco",
      "poster": GhostImage,
      "popupSrc": GhostVideo,
      "href": "https://opensea.io/assets/0x27c3dd629539f72d58360ffad470ad8fe4b72523/15400010001",
      "type": "video"
    },
    {
      "title": "Kawaii Multitasker",
      "description": "",
      "author": "Miguelgarest",
      "poster": KawaiiImage,
      "popupSrc": KawaiiVideo,
      "href": "https://opensea.io/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/243328",
      "type": "video"
    },
    {
      "title": "I Forget Where We Were",
      "description": "",
      "author": "Nate Hill",
      "poster": ForgetImage,
      "popupSrc": ForgetVideo,
      "href": "https://opensea.io/assets/0xaad5fc352ddf2b60074bbc9a6fd0867b2cc01547/30600030003",
      "type": "video"
    },
    {
      "title": "Still Chained To The Roots",
      "description": "",
      "author": "Gary Cartlidge",
      "poster": Chained,
      "popupSrc": Chained,
      "href": "https://opensea.io/assets/0x36d16e0758d1b52c28c4579e12b9efa0d23bab29/142",
      "type": "img"
    },
    {
      "title": "Tokyo Summer Dreams",
      "description": "",
      "author": "Elora Pautrat",
      "poster": Tokyo,
      "popupSrc": Tokyo,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/19286",
      "type": "img"
    },
    {
      "title": "Sad Snoopy",
      "description": "",
      "author": "Defaced",
      "poster": SadSnoopy,
      "popupSrc": SadSnoopy,
      "href": "https://opensea.io/assets/0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756/39224",
      "type": "img"
    },
    {
      "title": "World Inside",
      "description": "",
      "author": "Rolzay",
      "poster": WorldInsideImage,
      "popupSrc": WorldInsideVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/2446",
      "type": "video"
    },
    {
      "title": "Secrets",
      "description": "",
      "author": "jazsi",
      "poster": Secrets,
      "popupSrc": Secrets,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/663",
      "type": "img"
    },
    {
      "title": "Visiting Friends #5/5",
      "description": "",
      "author": "Alexis Christodoulou",
      "poster": VisitingFriendsImage,
      "popupSrc": VisitingFriendsVideo,
      "href": "https://opensea.io/assets/0xeeb9247a2d11ed4032125656a4b75daadfefaf79/29700050005",
      "type": "video"
    },
    {
      "title": "3AM #4/5",
      "description": "",
      "author": "Alexis Christodoulou",
      "poster": AM3Image,
      "popupSrc": AM3Video,
      "href": "https://opensea.io/assets/0xeeb9247a2d11ed4032125656a4b75daadfefaf79/29700060004",
      "type": "video"
    },
    {
      "title": "To the Moon",
      "description": "",
      "author": "Lushsux",
      "poster": MoonImage,
      "popupSrc": MoonVideo,
      "href": "https://opensea.io/assets/0x30f23d387c076d61fa5017516bedadee01a27356/8800010010",
      "type": "video"
    },
    {
      "title": "Why would I care if I'm just a cat?",
      "description": "",
      "author": "Mad Dog Jones",
      "poster": WhyCareImage,
      "popupSrc": WhyCareVideo,
      "href": "https://opensea.io/assets/0xd949a444f29ede73f517942eb4d8e3ccdab25d58/10400020438",
      "type": "video"
    },
    {
      "title": "George Floyd Portrait",
      "description": "",
      "author": "Micah Johnson & Matt Szczur",
      "poster": GeorgeFloyd,
      "popupSrc": GeorgeFloyd,
      "href": "https://opensea.io/assets/0x78c54a7523e7bdd63819a8affba7e8702cd48376/1100020004",
      "type": "img"
    },
    {
      "title": "Battle of the War Nymphs",
      "description": "",
      "author": "Grimes x Mac",
      "poster": BattleOfNymphs,
      "popupSrc": BattleOfNymphs,
      "href": "https://opensea.io/assets/0x948b3515d81034a3c16d5393c6c155946c93c103/27300020006",
      "type": "img"
    },
    {
      "title": "Singularity",
      "description": "",
      "author": "Balkan Karisman",
      "poster": SingularityImage,
      "popupSrc": SingularityVideo,
      "href": "https://opensea.io/assets/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/18041",
      "type": "video"
    },
    {
      "title": "Demonic Nyan Cat",
      "description": "",
      "author": "Christ Torres",
      "poster": DemonicCatImage,
      "popupSrc": DemonicCatVideo,
      "href": "https://opensea.io/assets/0xb32979486938aa9694bfc898f35dbed459f44424/19",
      "type": "img"
    },
    {
      "title": "Fancy Nyan Cat",
      "description": "",
      "author": "Christ Torres",
      "poster": NyanCatImage,
      "popupSrc": NyanCatVideo,
      "href": "https://opensea.io/assets/0xb32979486938aa9694bfc898f35dbed459f44424/10023",
      "type": "img"
    },
    {
      "title": "Touch",
      "description": "",
      "author": "3lau",
      "poster": TouchImage,
      "popupSrc": TouchVideo,
      "href": "https://opensea.io/assets/0x26b365dd6c6c2c5c1b19658ede5ef00c12402702/38",
      "type": "video"
    },
    {
      "title": "Caribbean SciFi",
      "description": "",
      "author": "Rafael Lacoste",
      "poster": Carribean,
      "popupSrc": Carribean,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/5449",
      "type": "img"
    },
    {
      "title": "Hong Kong 2049",
      "description": "",
      "author": "TOKYOLUV",
      "poster": HongkongImage,
      "popupSrc": HongkongVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/48466",
      "type": "video"
    },
    {
      "title": "Tokyo Metropolis",
      "description": "",
      "author": "Benjamin Lee",
      "poster": TokyoMetropolisImage,
      "popupSrc": TokyoMetropolisVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/22622",
      "type": "video"
    },
    {
      "title": "Tier 1, #10 - DRIVE",
      "description": "",
      "author": "Dave Krugman",
      "poster": Tier1Drive,
      "popupSrc": Tier1Drive,
      "href": "https://opensea.io/assets/0xec71ed650722ef6f98c1c28f0b34c9846c1ba10b/38",
      "type": "img"
    },
    {
      "title": "Skateboarding: Culture in Motion #7",
      "description": "",
      "author": "Jake Inez",
      "poster": SkateboardingCulture,
      "popupSrc": SkateboardingCulture,
      "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/74180388987835072097633391580607238135275622999050710486878638609709372801025",
      "type": "img"
    },
    {
      "title": "#40. Chang Mi, Thailand",
      "description": "",
      "author": "Jacob Briglin",
      "poster": ChangMi,
      "popupSrc": ChangMi,
      "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/14416656700475663864726672344569766568452912279528818547653479152721867046913",
      "type": "img"
    },
    {
      "title": "Bastard Countryside #35",
      "description": "",
      "author": "Robin Friend",
      "poster": BastardCountryside35,
      "popupSrc": BastardCountryside35,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/65749",
      "type": "img"
    },
    {
      "title": "Bastard Countryside #34",
      "description": "",
      "author": "Robin Friend",
      "poster": BastardCountryside34,
      "popupSrc": BastardCountryside34,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/65747",
      "type": "img"
    },
    {
      "title": "Memories fade away like cigarettes in the rain",
      "description": "",
      "author": "Tyler Shields",
      "poster": MemoriesCigaretesImage,
      "popupSrc": MemoriesCigaretesVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/77670",
      "type": "video"
    },
    {
      "title": "Payphone",
      "description": "",
      "author": "Tyler Shields",
      "poster": PayphoneImage,
      "popupSrc": PayphoneVideo,
      "href": "https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/52051",
      "type": "video"
    },
    {
      "title": "American Diners 1",
      "description": "",
      "author": "",
      "poster": AmericanDiners1,
      "popupSrc": AmericanDiners1,
      "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/19584883553916712241852398421302965913957422197351485086550879494722029617153",
      "type": "img"
    },
    {
      "title": "American Diners 2",
      "description": "",
      "author": "",
      "poster": AmericanDiners2,
      "popupSrc": AmericanDiners2,
      "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/19584883553916712241852398421302965913957422197351485086550879496921052872705",
      "type": "img"
    },
    {
      "title": "Urban anomalies",
      "description": "",
      "author": "Artist",
      "poster": UrbanAnomalies,
      "popupSrc": UrbanAnomalies,
      "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/90759427057310509927547570395708714779512351118091613108582219521065773694977",
      "type": "img"
    },
    {
      "title": "Form Farm Master Layer",
      "description": "",
      "author": "untitledxyz",
      "poster": FormFarmMaster,
      "popupSrc": FormFarmMaster,
      "href": "https://opensea.io/assets/0xb6dae651468e9593e4581705a09c10a76ac1e0c8/1908",
      "type": "img"
    },
  ]

  const clickPop = (e) => {
    /* const id = e.target.attributes.getNamedItem("datagrid").value; */
    var datagrid = e.target.getAttribute("datagrid");
    var thismans = masonryData[datagrid];
    var thislightbox = $(".custom-lightbox").eq(datagrid);
    var thispar = thislightbox.closest(".grid-item");
    var custom_lightbox = thispar.find('.custom-lightbox');

    var vid = custom_lightbox.find('video');
    if (vid.length > 0) {
      vid.attr("src", thismans['popupSrc']);
      setTimeout(() => {
        custom_lightbox.addClass('showlight');
        vid[0].play();
      }, 500);

    }
    else {
      var img = custom_lightbox.find('img');
      img.attr("src", thismans['popupSrc']);
      custom_lightbox.addClass('showlight');
    }
  }

  return (
    <>
      <div className="masonary__sec">
        <div className="masonary__inner">
          <div className="grid js-masonry" data-masonry='{ "itemSelector": ".grid-item"}'>

            {
              masonryData.map((value, index) => {
                return <div className="grid-item">
                  <div className="custom-lightbox">
                    <div onClick={clickPop} dataGrid={index} className="click_surface">
                    </div>
                    <a>
                      <img src={value.poster} alt="" />
                    </a>
                    <div className="lightbox-content">
                      <div className="close_light">close</div>

                      <div className="masonary_nav pre_pop">
                        <svg ariaHidden="true" focusable="false" dataPrefix="fal" dataicon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-chevron-left fa-w-8"><path fill="#fff" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" className=""></path></svg>
                      </div>
                      <div className="masonary_nav next_pop">
                        <svg ariaHidden="true" focusable="false" dataPrefix="fal" dataicon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-chevron-right fa-w-8"><path fill="#fff" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" className=""></path></svg>
                      </div>

                      {value.type == 'img' ? (
                        <img alt="" />
                      ) :
                        <video muted src={value.popupSrc} loop controls type="mp4"  ></video>
                      }
                      <div className="grid__content">
                        <h4 className="title_name">{value.title}</h4>
                        <h5 className="artist_name">{value.author}</h5>
                        <a target="_blank" href={value.href} className="css_btn artwork_link"><span>Go to artwok</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              })
            }




          </div>
        </div>
      </div>
      <Helmet>
        <script src="../assets/js/masonry.js" type="text/javascript" />
      </Helmet>
    </>
  )


}

export default CollectionMasonry