import { render } from '@testing-library/react'
import React, {Component} from 'react'

import footer_logo from '../assets/images/footer__logo.png'
 
class Footer extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <>
                <footer>
                    <div className="footer__bottom">
                        <div className="wrapper">
                            <div className="footer__bottom_inner">
                                <a href="/" className="footer__logo"><img src={`${footer_logo}`} alt=""/></a>
                                <div className="right__box">
                                    <ul className="social_icons">
                                        <li><a target="_blank" href="https://x.com/KanoseiVentures" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        {/* <li><a target="_blank" href="https://www.instagram.com/" target="_blank"><i className="fab fa-instagram"></i></a></li> */}
                                        {/* <li><a target="_blank" href="https://y.at/🎋🌕🎋" target="_blank"><i class="fas fa-hand-spock"></i></a></li> */}
                                    </ul>
                                    <span className="copyright">&copy; 2024 Kanosei All Rights Reserved</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default Footer;