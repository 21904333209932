import React, { Component } from 'react'
import $ from 'jquery';
import Shawarma from "../../assets/images/The-Last-Shawarma.mp4"



class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><video controls autoPlay loop muted src={Shawarma} alt=""></video></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">THE LAST SHAWARMA</h2>
                                <h2 className="artist_name"><span> By: </span> BOSS LOGIC </h2>
                                <div className="desc">
                                    <p>The Last Shawarma by Boss Logic</p>
                                </div>

                                <a href="https://opensea.io/assets/0x1e6ebe5e7aa3cd50d0d7838ac70c46102e84b5fa/22200010001" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;