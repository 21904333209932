import React, { useState } from "react";
import contactgif from '../../assets/images/contactgif.gif'
import emailjs from '@emailjs/browser';


const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        title: '',
        email: '',
        website: '',
        message: ''
    })

    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.send("service_3olq3na", "template_vltqje8", {
            to_name: "Admin",
            from_name: "Kanosei Team",
            first_name: formData.firstName,
            last_name: formData.lastName,
            organization: formData.organization,
            title: formData.title,
            email: formData.email,
            website: formData.website,
            message: formData.message,
        }, 'whkSiY-7HSg7lJu9O')
        setFormData(
            {
                firstName: '',
                lastName: '',
                organization: '',
                title: '',
                email: '',
                website: '',
                message: ''
            }
        )
    }
    return (
        <section className="contact__sec">
            <div className="wrapper">
                <div className="section_inner">
                    <div className="leftBlock">
                        <h2 className="heading">Get in touch</h2>
                        <p className="text">We’re always looking for compelling ideas, business, and partnership opportunities. We’d also love to hear from you if you’re working on a crypto, NFT, DAO, or general blockchain-related project or company. If you’re submitting a business proposal, please include a clear description and any resources that could help us evaluate your opportunity.</p>
                        <p className="text">If you are an artist or collector interested in joining our collection or acquiring an artwork, don’t hesitate to reach out, and we’ll get back to you as fast as we possibly can.</p>
                        <p className="text">For all other inquiries, please use the form below as well.</p>
                        <form onSubmit={sendEmail} className="contact__form">
                            <div className="form__row">
                                <div className="input__field">
                                    <input
                                        value={formData.firstName}
                                        onChange={({ target }) => setFormData({ ...formData, firstName: target.value })} type="text"
                                        placeholder="First Name" />
                                </div>
                                <div className="input__field">
                                    <input
                                        value={formData.lastName}
                                        onChange={({ target }) => setFormData({ ...formData, lastName: target.value })}
                                        type="text"
                                        placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="input__field">
                                    <input
                                        value={formData.organization}
                                        onChange={({ target }) => setFormData({ ...formData, organization: target.value })}
                                        type="text"
                                        placeholder="Organization" />
                                </div>
                                <div className="input__field">
                                    <input
                                        value={formData.title}
                                        onChange={({ target }) => setFormData({ ...formData, title: target.value })}
                                        type="text"
                                        placeholder="Title" />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="input__field">
                                    <input
                                        value={formData.email}
                                        onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                                        type="text"
                                        placeholder="Email" />
                                </div>
                                <div className="input__field">
                                    <input
                                        value={formData.website}
                                        onChange={({ target }) => setFormData({ ...formData, website: target.value })}
                                        type="text"
                                        placeholder="Website" />
                                </div>
                            </div>
                            <div className="form__row">
                                <div className="input__field">
                                    <textarea
                                        value={formData.message}
                                        onChange={({ target }) => setFormData({ ...formData, message: target.value })}
                                        name=""
                                        id=""
                                        cols=""
                                        rows=""
                                        placeholder="Message" />
                                </div>
                            </div>
                            <div className="form__row submit__row">
                                <div className="input__field">
                                    <button type="submit" className="css_btn submitBtn"><span>Submit</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="rightBlock"><img src={`${contactgif}`} alt="" /></div>
                </div>
            </div>
        </section>
    )
}

export default Contact