import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Main';
import reportWebVitals from './reportWebVitals';

//var pages_path = '../../';
var pages_path = 'https://staging4.ca/';
var images_path = 'assets/images/';

window.server_path = 'https://staging4.ca/';
window.src_path = `${window.server_path}src/`;
//var pages_path = 'https://staging4.ca/';
window.homepageRoute={
  gallery:[
    {
      title:'GOAT',
      content:'',
      imgpath:`${pages_path}assets/images/gallery1.jpg`,
      path:'goat',
      caption:'Beeple & Corridor Studio Collab',
      type:'img',
    },
    {
      title:'Eardley',
      content:'',
      imgpath:`${pages_path}${images_path}gallery2.jpg`,
      path:'eardley',
      caption:'Trevor Jones',
      type:'img',
    },
    {
      title:'Rain Village',
      content:'',
      imgpath:`${pages_path}${images_path}r.gif`,
      vidpath:`${pages_path}${images_path}r.gif`,
      path:'rain-village',
      caption:'SeerLight',
      type:'vid',
    },
    {
      title:'An Electric Storm',
      content:'',
      imgpath:`${pages_path}${images_path}gallery4.jpg`,
      path:'an-electric-storm',
      caption:'Reuben Wu',
      type:'img',
    },
    {
      title:'Universe Edge',
      content:'',
      imgpath:`${pages_path}${images_path}gallery5.jpg`,
      path:'universe-edge',
      caption:'Six n Five',
      type:'img',
    },
    {
      title:'Skateboard Rides You',
      content:'',
      imgpath:`${pages_path}${images_path}skate2.gif`,
      vidpath:`${pages_path}${images_path}skate2.gif`,
      path:'skateboard-rides-you',
      caption:'Deekaymotion',
      type:'vid',
    },
  ]
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
