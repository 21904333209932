import React, { Component } from "react";
import axios from 'axios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import $ from 'jquery';
import { Helmet } from "react-helmet";
import CollectionMasonry from "../CollectionMasonry";

import Seasons from '../../assets/images/Seasons.png'
import Raven from '../../assets/images/The-Raven.jpeg'
import VisualMeditation from '../../assets/images/Visual-Meditation.png'
import Martian from '../../assets/images/Martian.jpeg'
import SpaceStation from '../../assets/images/SpaceStation.png'
import Hyperreality from '../../assets/images/Hyperreality.png'
import UniverseMachine from '../../assets/images/the-Universe-Machine.png'
import Daughter from '../../assets/images/Daughter.png'
import RabbitHole from '../../assets/images/Down-the-Rabbit-Hole.png'
import Wavestep from '../../assets/images/Wavestep.gif'
import OnlyBeOne from '../../assets/images/There-can-only-be-one.png'
import Drop from '../../assets/images/The-Drop.png'
import Excalibur from '../../assets/images/Excalibur.png'
import Psychotropic from '../../assets/images/The-Psychotropic-Colony.png'

import CollectionHeroImage from '../../assets/images/collection_hero_image.png'



class Collection extends Component {
  src_path = window.src_path;
  constructor() {
    super();
    this.loadJs = this.loadJs.bind(this);
    this.FecthMasonry = this.FecthMasonry.bind(this);
    this.clickPop = this.clickPop.bind(this);


    this.state = {
      src_path: window.src_path,
      server_path: window.server_path,
      slider: [
        {
          title: 'Seasons',
          description: 'Blake Kathryn',
          img: Seasons,
          href: `seasons`,

        },
        {
          title: 'The Raven',
          description: 'Vini Naso',
          img: Raven,
          href: `the-raven`,
        },
        {
          title: 'Visual Meditation',
          description: 'Reisinger Andres',
          img: VisualMeditation,
          href: `visual-meditation`,
        },
        {
          title: 'The Martian',
          description: 'Fabio Giampetrio',
          img: Martian,
          href: `the-martian`,
        },
        {
          title: 'SpaceStation',
          description: 'Filip Hodas',
          img: SpaceStation,
          href: `spaceStation`,

        },
        {
          title: 'Hyperreality',
          description: 'Nessgraphics',
          img: Hyperreality,
          href: `hyperreality`,

        },
        {
          title: 'The Universe Machine',
          description: 'Joshua Davis',
          img: UniverseMachine,
          href: `the-universe-machine`,

        },
        {
          title: 'Daughter',
          description: 'Adam Swaab',
          img: Daughter,
          href: `daughter`,

        },
        {
          title: 'Down the Rabbit Hole',
          description: 'Andreas Wannerstedt',
          img: RabbitHole,
          href: `down-the-rabbit-hole`,

        }, {
          title: 'Wavestep',
          description: 'Neurocolor',
          img: Wavestep,
          href: `wavestep`,

        },
        {
          title: 'There can only be one',
          description: 'Gavin Shapiro',
          img: OnlyBeOne,
          href: `there-can-only-be-one`,

        },
        {
          title: 'The Drop',
          description: 'Alessio DeVecchi',
          img: Drop,
          href: `the-drop`,

        },
        {
          title: 'Excalibur',
          description: 'Mattey',
          img: Excalibur,
          href: `excalibur`,

        },
        {
          title: 'The Psychotropic Colony',
          description: 'GMUNK',
          img: Psychotropic,
          href: `the-psychotropic-colony`,

        },
      ],

      masonry: []
    }
  }




  clickPop(e) {
    /* const id = e.target.attributes.getNamedItem("datagrid").value; */
    var datagrid = e.target.getAttribute("datagrid");
    var thismans = this.state.masonry[datagrid];
    var thislightbox = $(".custom-lightbox").eq(datagrid);
    var thispar = thislightbox.closest(".grid-item");
    var custom_lightbox = thispar.find('.custom-lightbox');

    var vid = custom_lightbox.find('video');
    if (vid.length > 0) {
      vid.attr("src", thismans['popupSrc']);
      setTimeout(() => {
        custom_lightbox.addClass('showlight');
        vid[0].play();
      }, 500);

    }
    else {
      var img = custom_lightbox.find('img');
      img.attr("src", thismans['popupSrc']);
      custom_lightbox.addClass('showlight');
    }
  }

  loadJs() {

    $.fn.jQuerySimpleCounter = function (options) {
      var settings = $.extend({
        start: 0,
        end: 100,
        easing: 'swing',
        duration: 400,
        complete: ''
      }, options);

      var thisElement = $(this);

      $({ count: settings.start }).animate({ count: settings.end }, {
        duration: settings.duration,
        easing: settings.easing,
        step: function () {
          var mathCount = Math.ceil(this.count);
          thisElement.text(mathCount);
        },
        complete: settings.complete
      });
    };


    $('#number1').jQuerySimpleCounter({ end: 170, duration: 3000 });
    $('#number2').jQuerySimpleCounter({ end: 1000, duration: 3000 });
    $('#number3').jQuerySimpleCounter({ end: 10, duration: 2000 });

    /*  $('.grid').masonry({
         itemSelector: '.grid-item'
     }); */


    $(document).keydown(function (e) {
      if (e.keyCode == 27) {
        $(".custom-lightbox").each(function () {
          if ($(this).hasClass('showlight')) {
            var close_light = $(this).find('.close_light');
            close_light.trigger('click');
          }
        });
      }
    });


    /* $("html body").on('click','.custom-lightbox',function(e){

        
        
    }); */

    $("html body").on('click', '.close_light', function (e) {
      e.stopPropagation();
      var thispar = $(this).closest(".custom-lightbox");
      thispar.removeClass('showlight');
      var vid = thispar.find('video');
      if (vid.length > 0) {
        var jsvid = vid.get(0);

        if (jsvid.paused) {
        } else {
          vid[0].pause();
        }

      }

    });


    $("html body").on('click', '.next_pop', function (e) {
      e.stopPropagation();
      var custom_lightbox = $(".showlight");
      if (custom_lightbox.length > 0) {
        var this_grid_item = $(this).closest('.grid-item');
        var thisindex = $(".grid-item").index(this_grid_item);
        var next_index = thisindex + 1;
        var next_item = $(".grid-item").eq(next_index);
        if (next_item.length == 0) {
          next_index = 0;
          next_item = $(".grid-item").eq(next_index);
        }

        var pre_close = this_grid_item.find('.close_light');
        pre_close.trigger('click');

        var next_click = next_item.find('.click_surface');
        next_click.trigger('click');
      }
    });

    $("html body").on('click', '.pre_pop', function (e) {
      e.stopPropagation();
      var custom_lightbox = $(".showlight");
      if (custom_lightbox.length > 0) {
        var this_grid_item = $(this).closest('.grid-item');
        var thisindex = $(".grid-item").index(this_grid_item);
        pre_index = thisindex - 1;
        if (thisindex == 0) {
          var pre_index = ($(".grid-item").length) - 1;
        }

        var pre_item = $(".grid-item").eq(pre_index);
        var this_close = this_grid_item.find('.close_light');
        this_close.trigger('click');

        var pre_click = pre_item.find('.click_surface');
        pre_click.trigger('click');
      }
    });
  }
  clickMenu() {
    $("#page-loader").show();
    window.scrollTo(0, 0);
    setTimeout(function () { $("#page-loader").fadeOut(1500); }, 3000);
    $(".qodef-follow-info-holder").remove();
  }

  FecthMasonry() {
    axios.get(`masnory_json.json`)
      .then(res => {
        var data = res.data;
        var options_arr = [];
        for (var x in data) {
          options_arr.push(data[x]);

        }

        this.setState({
          masonry: []
        })
      })
  }

  /* FecthGallery(){
      axios.get(`gallery_json.json`)
          .then(res => {
              var data = res.data;
              var options_arr = [];
              for(var x in data){
                  options_arr.push(data[x]);    

              }
              
              this.setState({
                  slider:options_arr
              })
          })
  } */


  componentDidMount() {
    this.loadJs()
  }

  componentWillMount() {
    this.FecthMasonry();
    /* this.FecthGallery(); */
  }

  /* ShowPop(event){
      console.log(event.target);
  } */


  render() {
    return (
      <>
        <div className="collection__slider_main">
          <div className="wrapper">
            <div className="swiper mySwiper collection__slider">
              <div className="swiper-wrapper">
                {
                  this.state.slider.map((value, index) => {
                    return <div className="swiper-slide">
                      <Link onClick={() => this.clickMenu()} to={`portfolio-item/${value.href}`} className="slide__img">
                        <img src={value.img} alt="" />
                      </Link>
                      <div className="slide__content">
                        <h5 className="title">{value.title}</h5>
                        <p className="desc">{value.description}</p>
                      </div>
                    </div>
                  })
                }
              </div>
            </div>

            <div className="swiper-button-prev">
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30" xmlSpace="preserve"><g><polygon fill="#BABABA" points="19.178,25.979 19.982,25.388 12.633,15.378 19.982,5.37 19.178,4.778 11.393,15.378"></polygon></g></svg>
              </span>

            </div>
            <div className="swiper-button-next">
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30" xmlSpace="preserve"><g><polygon fill="#BABABA" points="12.198,25.979 11.392,25.388 18.742,15.378 11.392,5.37 12.198,4.778 19.982,15.378"></polygon></g></svg>
              </span>

            </div>
          </div>
        </div>


        <div className="counter__div">
          <div className="wrapper">
            <div className="counter_main">
              <div className="counter__box">
                <div className="number number1"><span id="number1"></span><span>+</span></div>
                <span className="text">Unique artists represented in our collection</span>
              </div>
              <div className="counter__box">
                <div className="number number2"><span id="number2"></span><span>+</span></div>
                <span className="text">Artworks collected</span>
              </div>
              <div className="counter__box">
                <div className="number number3"><span>$</span><span id="number3"></span><span>m</span></div>
                <span className="text">Estimated collection value</span>
              </div>
            </div>
          </div>
        </div>

        <section className="about__main collection_page">
          <div className="about_header">
            <div className="left__panel">
              <h2 className="heading styled">The Kanosei Collection is composed of NFTs that capture the new possibilities of art in the Web3 era.</h2>
              <div className="desc">
                <p>The collection spans a breadth of works from ones that translate traditional mediums into digital, such as those by Trevor Jones and Reuben Wu, to ones that explore the possibilities of digital art, such as generative works by Casey Reas and Zeblocks via ArtBlocks. Works that capture social phenomenon and shared culture, such as Marvel, anime, memes, games, and beautiful scene scapes, are also a big part of the collection.</p>
                <p>As the NFT space burgeons and shifts the traditional contexts, we aim to discover works that explore new paradigms in how art can be created and altered, applied to the metaverse as well as support works of new artists and of those from minority communities.</p>
                <p>Individually, the works we collect reflect the unique artist’s styles and journey.</p>
                <p>As a whole, our collection is a record, a documentation of NFTs as they were, as they are, as they will be.</p>
              </div>
              <h4 className="small_heading styled">Curated by Matt <a href="https://twitter.com/y_kymin" target="_blank">(@y_kymin)</a> and Tom <a href="https://twitter.com/think_flexible" target="_blank">(@think_flexible)</a></h4>
            </div>
            <div className="right__panel">
              <img src={CollectionHeroImage} alt="" />
            </div>
          </div>
        </section>

        <CollectionMasonry />



        <Helmet>
          <script src="../../assets/js/swiper-bundle.min.js" type="text/javascript" />
          <script src="../../assets/js/amoli-core2.js" type="text/javascript" />
        </Helmet>
      </>
    )
  }
}

export default Collection;