import React, { Component } from 'react'
import $ from 'jquery';
import GOAT from "../../assets/images/gallery1.jpg"


class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><img src={GOAT} alt="" /></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">GOAT</h2>

                                <h2 className="artist_name"><span> By: </span> Beeple & Corridor Studio Collab </h2>
                                <div className="desc">
                                    <p>Hey, who’s in charge here?</p><p>This collaboration between Beeple and Peter France was created using Cinema 4D, Bellus3D for the heads, Octane Render, and Adobe Photoshop.</p>
                                </div>

                                <a href="https://opensea.io/assets/0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756/50472" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;