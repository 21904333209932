import React, { Component } from 'react'
import $ from 'jquery';
import Excalibur from "../../assets/images/Excalibur.mp4"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <video controls autoPlay loop muted src={Excalibur} alt="The Excalibur"></video>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Excalibur</h2>

                                <h2 className="artist_name"><span> By: </span> Mattey </h2>
                                <div className="desc">
                                    <p>Crypto-Sword 2000x2190px 350 Frames, 60 FPS.</p><p>Excalibur can only be removed from the rock by the one who would become the future King. No one has yet succeeded.</p><p>This act could only be performed by the king chosen by the Gods. Excalibur could slice anything and make whoever takes it invincible. </p><p>Who’s gonna get it ? Who’s gonna be the king ? 👑 ...</p>
                                </div>

                                <a href="https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/1233" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;