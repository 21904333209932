import React, { Component } from 'react'
import $ from 'jquery';
import Seasons from '../../assets/images/Seasons.mp4'


class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <video controls autoPlay muted loop src={Seasons} alt="Seasons"></video>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Seasons</h2>

                                <h2 className="artist_name"><span> By: </span> Blake Kathryn </h2>
                                <div className="desc">
                                    <p>A reflection of time passed within familiar walls ✧ Score by Mariode ✧ 1080x1350</p>
                                </div>

                                <a href="https://opensea.io/assets/ethereum/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/17161" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;