import React, { Component } from 'react'
import $ from 'jquery';
import Electric from "../../assets/images/Electric-Storm-High-64k.mp4"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><video controls autoPlay muted loop src={Electric} alt=""></video></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">An Electric Storm</h2>

                                <h2 className="artist_name"><span> By: </span> Reuben Wu </h2>
                                <div className="desc">
                                    <p>“An Electric Storm” is the concluding chapter of my genesis Lux Noctis motion series. Conceived in 2016, this groundbreaking project draws inspiration from chiaroscuro painting, planetary exploration, and science fiction, expressing my unique connection with the landscape.</p><p>The physical component is a 1/1 fine art print infused with the NFT animation. This novel hybrid allows the artwork to be viewed in its AR mode while also functioning as a framed wall piece.</p><p>📷 &amp; 🎹 : Reuben Wu</p>
                                </div>
                                <a href="https://opensea.io/assets/0x3b3ee1931dc30c1957379fac9aba94d1c48a5405/54209" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;