import React, { Component } from 'react'
import $ from 'jquery';
import Martian from "../../assets/images/slideImg2.jpg"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><img src={Martian} alt="" /></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading"> Martian </h2>
                                <div className="desc">
                                    <p>Lorem ipsum dolor sit amet, consecteturus adipisc elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua uten enim ad minim veniam, quis nostrud. exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;