import React, { Component } from 'react'
import $ from 'jquery';
import Eardley from "../../assets/images/gallery2.jpg"

class PortfolioGoat extends Component {
    server_path = window.src_path;
    constructor() {
        super()
    }

    loadInitJs() {
        /* var item_name = this.pr */
        /* console.log(item_name); */
    }
    componentDidMount() {
        this.loadInitJs();
    }
    render() {
        return (
            <>
                <div className="portfolio__main">
                    <div className="wrapper">
                        <div className="portfolio__inner">
                            <div className="left__panel">
                                <div className="image_div">
                                    <a href=""><img src={Eardley} alt="" /></a>
                                </div>
                            </div>
                            <div className="right__panel">
                                <h2 className="heading">Eardley</h2>

                                <h2 className="artist_name"><span> By: </span> Trevor Jones </h2>
                                <div className="desc">
                                    <p>Oil on canvas. 90 x 90 cm. (36 x 36 inches). 2012 (scannable QR code painting).</p>
                                </div>

                                <a href="https://opensea.io/assets/0xc4ea5b0abe37addf21809754feeabee5abbe9b15/27100160001" className="css_btn"><span>VIEW ARTWORK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PortfolioGoat;