import React,{Component} from "react";
import $ from 'jquery';
import mainLogo from '../assets/images/main__logo.png';

class Sidebar extends Component{
    constructor(){
        super()
        this.BackToTop = this.BackToTop.bind(this);
    }

    BackToTop(){
        $('html, body').animate({scrollTop:0}, '300');
    }

    hideLoader = () =>{
        $(function() {
            setTimeout(function() { $("#page-loader").fadeOut(1500); }, 3000);

            $('body').on('click', '.sidebar__toggle', function(){
                $('body').toggleClass('sidebar_active');
            });
            $('body').on('click', '.sidebar__cover', function(){
                $('body').removeClass('sidebar_active');
            });

            var btn = $('#back_to_top');
            $(window).scroll(function() {
                    if ($(window).scrollTop() > 300) {
                        btn.addClass('show');
                    } else {
                        btn.removeClass('show');
                    }
            });

            var $nav = $('body'),
            $win = $(window),
            winH = $win.height();    // Get the window height.

        $win.on("scroll", function () {
            $nav.toggleClass("fix__header", $(this).scrollTop() > winH );
            }).on("resize", function(){ // If the user resizes the window
            winH = $(this).height(); // you'll need the new height value
        });
        
        })
    }

    componentDidMount(){
        this.hideLoader();
    }

    render(){

        
        return (
            <>
                <div className="sidebar__cover"></div>
                    <div className="sidebar">
                        <div className="sidebar_inner">
                            <a href="#" className="site__logo"><img src={ `${mainLogo}` } alt=""/></a>
                            <p className="desc">Investing in the 'possibilites' of Web3</p>
                            <ul className="social_icons">
                                <li><a href="https://twitter.com/KanoseiVentures" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                {/* <li><a href="https://www.instagram.com/" target="_blank"><i className="fab fa-instagram"></i></a></li> */}
                                {/* <li><a href="https://y.at/🎋🌕🎋" target="_blank"><i class="fas fa-hand-spock"></i></a></li> */}
                            </ul>
                            <span className="copyright">All Rights Reserved &copy; Kanosei 2024</span>
                        </div>
                    </div>
                    <div className="back_to_top" onClick={ () => this.BackToTop() } id="back_to_top">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="10px" viewBox="0 0 21 10" enableBackground="new 0 0 21 10" xmlSpace="preserve">
                            <polyline fill="none" stroke="#fff" points="0.5,9.071 10.5,1.727 20.5,9.071 "></polyline>
                        </svg>
                    </div>
            </>
        )
    }
}

export default Sidebar; 