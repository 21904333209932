import React, {useState,useEffect} from 'react'
import './assets/css/lightbox.min.css';
import './assets/css/style.css';
import './assets/css/layout.css';

import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Loader from './components/Loader';



function App() {

    /* const hideLoader = () =>{
        $(function() {
            setTimeout(function() { $("#page-loader").fadeOut(1500); }, 3000)
        })
    } */

    useEffect(()=>{
        /* hideLoader(); */
    },[])
  return (
   <>
    <Header></Header>
    <Footer></Footer>
    <Sidebar></Sidebar>
    <Loader></Loader>
    </>
  )
}

export default App;
